#testimonials {
    padding-bottom: 0;
}

.swiper {
    height: fit-content;
    padding-bottom: 12rem;
    /* this paddint top is to give space for micro interaction on hover of swiper slide */
    padding-top: 1rem;
}

.swiper-slide {
    cursor: grab;
}

.testimonial__client {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
}

.testimonial__client-avatar {
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius-2);
    overflow: hidden;
    transition: var(--transition);
}


.testimonial__client-link .btn {
    /* font-size: 0.8rem; */
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-left: 3rem;
    display: inline-block;
    line-height: 1;

}

.testimonial__client-link .btn:hover {
    transform: translateY(3px);
    background-color: var(--color-white);
}
  

.swiper-pagination {
    margin-bottom: 8rem;
}


.swiper-pagination-bullet {
    background: var(--color-primary);
}